<template>
  <div v-if="pkg" class="container">
    <div v-for="line in pkg.productlines" :key="line.id">
      <div class="row align-items-center">
        <div class="col">
          <h3>{{ line.remark }}</h3>
          <p>Aantal: {{ line.qty }}</p>
          <p>Breedte: {{ line.width }}</p>
          <p>Hoogte: {{ line.height }}</p>
        </div>
        <div class="col" @click="image = line.lowres">
          <img :src="line.lowres" />
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  name: "Package",
  props: {
    pkg: Object,
  },
};
</script>

<style>
img {
  max-height: 200px;
  object-fit: cover;
}
</style>
